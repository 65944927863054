import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import * as glob from '../../../../shared/global';
import {Observable} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class InfoService {

    constructor(private _httpClient: HttpClient) {
    }

    GetInfo(): Observable<any> {
        return this._httpClient.get(`${glob.BASE_URL}agency/info`);
    }

    ChangeInfo(formData): Observable<any> {
        return this._httpClient.post(`${glob.BASE_URL}agency/change-info`, formData);
    }

    ChangePassword(formData): Observable<any> {
        return this._httpClient.post(`${glob.BASE_URL}agency/change-password`, formData);
    }


    AddUserAgent(data): Observable<any> {
        return this._httpClient.post(`${glob.BASE_URL}agency/create-user-from-master`, data);
    }

    UpdateUserAgent(data): Observable<any> {
        return this._httpClient.post(`${glob.BASE_URL}agency/change-user-agency`, data);
    }

    ShowPerOfUserAgency(id): Observable<any> {
        return this._httpClient.get(`${glob.BASE_URL}agency/show-agency-assign-permission?user_id=${id}`);
    }

    ShowInfoOfUserAgency(id): Observable<any> {
        return this._httpClient.get(`${glob.BASE_URL}agency/show-info-user-agency?user_id=${id}`);
    }

    ChangeStatusOfUserAgency(id): Observable<any> {
        return this._httpClient.post(`${glob.BASE_URL}agency/disable-agency`, id);
    }

    UpPerOfUserAgency(formData): Observable<any> {
        return this._httpClient.post(`${glob.BASE_URL}agency/update-user-agency-permission`, formData);
    }


}
