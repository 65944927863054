export class User {
    name: string;
    email: string;
    phone: string;
    address: string;
    score: string;
    password: string;
    new_password: string;
    confirm_password: string;
    alias: string;

    constructor() {
        this.name = '';
        this.email = '';
        this.phone = '';
        this.address = '';
        this.alias = '';
        this.score = '';
        this.password = '';
        this.new_password = '';
        this.confirm_password = '';
    }
}
