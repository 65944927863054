export class Filter {
    res_num: string;
    res_ref: string;
    agent_name: string;
    note: string;
    status: string;
    flight_code: string;
    city_pair: string;
    locator: string;

    constructor() {
        this.res_num = '';
        this.res_ref = '';
        this.agent_name = '';
        this.note = '';
        this.status = '';
        this.flight_code = '';
        this.city_pair = '';
        this.locator = '';
    }
}
