import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import * as glob from '../../../../../shared/global';
import {Observable} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ReservationHistoryService {

    public token = localStorage.getItem('currentUser');
    public accessKey = localStorage.getItem('accessKey');

    constructor(private _httpClient: HttpClient) {
    }

    GetNegotiation(id): Observable<any> {
        return this._httpClient.get(`${glob.BASE_URL}booking/history-negotiation?booking_id=${id}`);
    }

    GetListDeposit(id): Observable<any> {
        return this._httpClient.get(`${glob.BASE_URL}payment/list-res-payment?res_number=${id}`);
    }

    RefreshDetailBooking(id): Observable<any> {
        return this._httpClient.post(`${glob.BASE_URL}booking/split-reservation`, id);
    }

    SendEmailCaptcha(data): Observable<any> {
        return this._httpClient.post(`${glob.BASE_URL}payment/send-captcha`, data);
    }

    ConfirmNegotiation(id): Observable<any> {
        return this._httpClient.get(`${glob.BASE_URL}booking/confirm-agent-reservation?booking_id=${id}`);
    }

    CheckFareDif(id): Observable<any> {
        return this._httpClient.get(`${glob.BASE_URL}booking/check-fare-difference/${id}`);
    }

    DeleteNegotiation(id, data): Observable<any> {
        return this._httpClient.post(`${glob.BASE_URL}booking/cancel-reservation?booking_id=${id}`, data);
    }

    SendNegotiation(formdata): Observable<any> {
        return this._httpClient.post(`${glob.BASE_URL}booking/agency-send-negotiation`, formdata);
    }

    SendPayment(formdata): Observable<any> {
        return this._httpClient.post(`${glob.BASE_URL}payment/payment`, formdata);
    }

    GetDetailFlight(id): Observable<any> {
        return this._httpClient.get(`${glob.BASE_URL}booking/check-reservations?booking_id=${id}`);
    }

    SendMail(id): Observable<any> {
        return this._httpClient.get(`${glob.BASE_URL}booking/check-ticket?booking_id=${id}`);
    }

    UpdateBooking(id): Observable<any> {
        return this._httpClient.get(`${glob.BASE_URL}agency/update-booking/${id}`);
    }

    AddCommentAgent(formData): Observable<any> {
        return this._httpClient.post(`${glob.BASE_URL}booking/add-comment-agent`, formData);
    }

    AddRequestPayment(formData): Observable<any> {
        return this._httpClient.post(`${glob.BASE_URL}agency/add-request-payment`, formData);
    }

    GetNotification(id): Observable<any> {
        return this._httpClient.get(`${glob.BASE_URL}agency/notification?booking_id=${id}`);
    }

    PaymentArrears(data): Observable<any> {
        return this._httpClient.post(`${glob.BASE_URL}agency/payment-arrears`, data);
    }

    ExportHistorySearch(filter) {
        window.open(`${glob.BASE_URL}booking/export-list-reservation?${filter}&secret_key=` + this.accessKey);
    }

    ExportListPayment(filter) {
        window.open(`${glob.BASE_URL}booking/export-list-reservation-payment?${filter}&secret_key=` + this.accessKey);
    }

    ExportPaymentHistory(filter) {
        window.open(`${glob.BASE_URL}booking/export-list-reservation-history-payment?${filter}&secret_key=` + this.accessKey);
    }

    ExportRefundRequest(filter) {
        window.open(`${glob.BASE_URL}booking/export-list-reservation-refund?${filter}&secret_key=` + this.accessKey);
    }

    GetDiscount(): Observable<any> {
        return this._httpClient.get(`${glob.BASE_URL}agency/show-discount`);
    }

}
