import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class SharedDataService {
    public dataStep1: any = {};
    public dataStep2: any = {};
    public dataStep3: any = {};

    constructor() {
    }
}
