import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import * as glob from '../../../../../shared/global';
import {Observable} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ReservationBookingService {

    constructor(private _httpClient: HttpClient) {
    }

    CancelBooking(id, data): Observable<any> {
        return this._httpClient.post(`${glob.BASE_URL}booking/cancel-reservation?booking_id=${id}`, data);
    }

    RefundRequestBooking(id, data): Observable<any> {
        return this._httpClient.post(`${glob.BASE_URL}booking/send-refund-request-agent?booking_id=${id}`, data);
    }

    GetDatatableImport(id): Observable<any> {
        return this._httpClient.get(`${glob.BASE_URL}booking/list-passenger?booking_id=${id}`);
    }

    GetNotification(id): Observable<any> {
        return this._httpClient.get(`${glob.BASE_URL}agency/notification?booking_id=${id}`);
    }

    ImportList(formData): Observable<any> {
        return this._httpClient.post(`${glob.BASE_URL}booking/passenger-import`, formData);
    }

    ExportList(id_import: number) {
        const accessKey = localStorage.getItem('accessKey');
        window.open(`${glob.BASE_URL}booking/download-passenger?booking_id=${id_import}&secret_key=` + accessKey);
    }

    SendMail(id_import: number) {
        return this._httpClient.get(`${glob.BASE_URL}booking/check-ticket?booking_id=${id_import}`);
    }

    GetListFoodAncillary(id): Observable<any> {
        return this._httpClient.get(`${glob.BASE_URL}booking/ancillary?booking_detail_id=${id}`);
    }

    GetListCusAncillary(id): Observable<any> {
        return this._httpClient.get(`${glob.BASE_URL}booking/list-ancillary?booking_detail_id=${id}`);
    }

    SendAncillary(data): Observable<any> {
        return this._httpClient.post(`${glob.BASE_URL}booking/post-ancillary`, data);
    }

    PreviewAncillary(data): Observable<any> {
        return this._httpClient.post(`${glob.BASE_URL}booking/preview-ancillary`, data);
    }

    ExportBookingReference(filter) {
        const accessKey = localStorage.getItem('accessKey');
        window.open(`${glob.BASE_URL}booking/export-list-reservation-reference?${filter}&secret_key=` + accessKey);
    }

    ShowRequestDropDown(): Observable<any> {
        return this._httpClient.get(`${glob.BASE_URL}agency/type-request`);
    }

    ShowReason(): Observable<any> {
        return this._httpClient.get(`${glob.BASE_URL}agency/show-reason`);
    }

    SendRequest(data): Observable<any> {
        return this._httpClient.post(`${glob.BASE_URL}agency/add-request`, data);
    }
}
